<template>
  <main class="member-shop-product-settings">
    <PageTitle title="商城商品設定" hideBtn />
    <BasicTabs
      v-model="activeTab"
      class="mb-[20px]"
      :tabs="tabs"
      @change="tabChange"
    />
    <MemberShopProductBlock v-if="activeTab === 'normal'" />
    <SubscriptionProductBlock v-if="activeTab === 'subscription'" />
  </main>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import { usePermissions } from '@/use/permissions'
import MemberShopProductBlock from './components/MemberShopProductBlock.vue'
import SubscriptionProductBlock from './components/SubscriptionProductBlock.vue'
import BasicTabs from '@/components/Tabs/BasicTabs.vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { get } from 'lodash'

export default defineComponent({
  name: 'MemberShopProductSettings',
  components: { MemberShopProductBlock, SubscriptionProductBlock, BasicTabs },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const { checkAction } = usePermissions()

    const activeTab = ref('normal')
    const useSubscribeProduct = computed(() => checkAction('admin.subscriptionConfig.page'))
    const tabQuery = computed(() => route.query.tab)
    const tabs = computed(() => {
      return useSubscribeProduct.value ? [{ label: '一般商品', value: 'normal' }, { label: '訂閱方案商品', value: 'subscription' }] : [{ label: '一般商品', value: 'normal' }]
    })
    const tabChange = (tab) => {
      router.push({
        name: 'MemberShopProductSettings',
        query: {
          tab,
        },
      })
    }
    onMounted(() => {
      activeTab.value = tabQuery.value ? tabQuery.value : 'normal'
    })
    return {
      useSubscribeProduct,
      activeTab,
      tabs,
      tabChange,
    }
  },
})
</script>

<style scoped lang="scss">
::v-deep {
  .el-tabs__item {
    @apply text-base font-medium h-auto leading-loose px-[14px];
    &:hover {
      @apply text-primary-100;
    }
    &.is-active {
      @apply text-primary-100;
    }
  }
  .el-tabs__active-bar {
    @apply bg-primary-100 h-[4px];
  }
  .el-tabs__nav-wrap::after {
    @apply content-none;
  }
}
</style>
